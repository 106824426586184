import axios from "axios"


export const getBaseUrl = () => {
    // let url;
    // if(applicationEnv === "Development")
    // {
    //   url = "http://localhost:4003/api/v1/"
    // }
    // if (applicationEnv === "Staging") {
    //   url = "https://techno-api.moshimoshi.cloud/api/v1/";
    // }
    // if(applicationEnv === "Production"){
    //   url = " https://techno-api.eastus.cloudapp.azure.com/api/v1/"
    // }
    // return url;

    return process.env.REACT_APP_BACKEND_URL;
  }

export default axios.create({
    baseURL: getBaseUrl(),
})