import React from 'react'
import "./OurClientsCarousels.css"

const OurClientCarousels = ({ cardData }) => {
    return (
        <div className='inner-clients-size' >
            <img className='' src={cardData.img} alt=""/>
        </div>
    )
}

export default OurClientCarousels
