import React, { useMemo, useState } from "react";
import "./Partners.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import BG1 from "../../assets/images/Partners/bg1.svg";
import PartnersImage from "../../assets/images/Partners/PartnersImage.png";
import CourseCard from "../../components/CourseCard/CourseCard";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import partners1 from "../../assets/images/Partners/partner1.svg";
import Azure from "../../assets/images/Partners/Azure.svg";
import level from "../../assets/images/Partners/level.svg";
import schedule from "../../assets/images/Partners/schedule.svg";
import CalendarComp from "./Calender";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { apiUrls } from "../../api/apiUrls";
import http from "../../services/axios/axiosInstance";
import "react-toastify/dist/ReactToastify.css";
import { changeLanguageText } from "../../services/helper";
import VerifyPartner from "../../hoc/VerifyPartner";
import IsDisplayPartnersPageContent from "../../hoc/IsDisplayPartnerPageContent";

const Partners = () => {
  const navigate = useNavigate();
  const title = "Partners";
  const crumbs = [{ name: "Partners", link: "/partners" }];

  // const [value, onChange] = useState(new Date());
  const [calenderdata, setCalenderData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // let applicationFormResponse = useSelector((state) => state.partnerCourseState)

  const getCalenderDate = async () => {
    try {
      let config = {
        method: "get",
        url: `${apiUrls.partnerCourse}`,
      };
      let res = await http(config);
      // console.log(res)
      // console.log("DATA", data.result)
      setCalenderData(res?.data?.result);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handlePartnerCourse = (partnerCourseData) => {
    navigate(`/partner-course-form?partnerCourseId=${partnerCourseData.id}`);
  };

  const activeLanguage = useSelector((state) => state.languageState.lang);
  const [pageData, setPageData] = useState({});

  const getTranslatedData = async () => {
    setPageData({
      partners: await changeLanguageText("Partners", activeLanguage),
      hello: await changeLanguageText("HELLO PARTNERS", activeLanguage),
      partners1: await changeLanguageText(
        "Partner With Us To Become",
        activeLanguage
      ),
      partners2: await changeLanguageText(
        "A Technofocus Trainer",
        activeLanguage
      ),
      partnersDesc: await changeLanguageText(
        "We, at Technofocus, are dedicated to creating a dynamic, value-based learning experience for our trainees. With your expertise, help us spread the knowledge and skills on the latest in Microsoft cloud. Choose from the upcoming engagements, match your skills to the course requirements, and submit your application! We will review your application and get in touch with you.",
        activeLanguage
      ),
      upcoming: await changeLanguageText(
        "Upcoming Opportunities",
        activeLanguage
      ),
      upcomingDesc: await changeLanguageText(
        "Browse through the upcoming sessions and apply for the course that matches your skills. Partner up with Technofocus and revolutionise professionals’ learning experience.",
        activeLanguage
      ),
      entername: await changeLanguageText("Enter Course Name", activeLanguage),
      courses: await changeLanguageText("COURSES", activeLanguage),
      calender: await changeLanguageText("CALENDER", activeLanguage),
      handsDesc: await changeLanguageText(
        "Hands on labs are virtual labs curated to enable your learning process. As a part of our training programs, Hands-on-labs provide you with much-needed firsthand experience in Microsoft cloud technologies in a virtual environment. Get step-by-step instructions and understand the key concepts to the core with practical training.",
        activeLanguage
      ),
      learnMore: await changeLanguageText("Learn More", activeLanguage),
      handson: await changeLanguageText("Hands On Lab", activeLanguage),
      datanotfound: await changeLanguageText("Data Not Found", activeLanguage),
      exploremore: await changeLanguageText("Explore More", activeLanguage),
      competitive: await changeLanguageText("Competitive", activeLanguage),
      challange: await changeLanguageText(
        "Challenge Experience",
        activeLanguage
      ),
      experience1: await changeLanguageText(
        "With Challenge Experiences you get to solve business challenges and evaluate your progress. We allow you to apply what you have learned and assess how far you have advanced. With a top-down approach to learning, Challenge Experience is the perfect platform for professionals to push the boundaries of skill acquisition.",
        activeLanguage
      ),
    });
  };

  const handelSearchQuery = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getCalenderDate();
    getTranslatedData();
  }, [activeLanguage]);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return calenderdata;
    } else {
      return calenderdata.filter((item) => {
        if (
          item.course_about.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.course_name.toLowerCase().includes(searchQuery.toLowerCase())
        ) {
          return true;
        } else return false;
      });
    }
  }, [searchQuery, calenderdata]);

  return (
    <>
      {console.log(calenderdata)}
      <HeroSection
        title={pageData?.partners}
        crumbs={[{ name: pageData.partners, link: "/partners" }]}
      />

      <div className="main-container">
        <div className="row partnersRow">
          {/* left panel */}
          <div className="col-lg-6 h-100">
            <div className="mt-5 position-relative">
              <p className="abouts-us-title color-blue">{pageData?.hello}</p>
              <h2>
                {pageData?.partners1} <br /> {pageData?.partners2}
              </h2>
              <p className="text-muted">{pageData?.partnersDesc}</p>
              <img
                className="position-absolute partnersBg1 hideformobile"
                src={BG1}
                alt=""
              />
            </div>
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 h-100">
            <div className="row px-2 p-lg-5 position-relative">
              <img
                src={PartnersImage}
                className="objectFitCover p-0 shadow"
                id="partnersImage"
                alt="Technofocus partners page"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="main-container mt-4 bg-light   ">
        <div className="row m-0 p-3 pb-5 d-flex justify-content-center upcomingOpportuninties bg-blue position-relative">
          <h2 className="mt-3 d-flex text-white justify-content-center">
            {pageData?.upcoming}
          </h2>
          <p className="my-3 d-flex text-white justify-content-center text-center w-75">
            {pageData?.upcomingDesc}
          </p>
          <IsDisplayPartnersPageContent>
            <div className="d-flex text-white justify-content-center mt-3 position-absolute partnersSearch">
              <div className="d-flex justify-content-between bg-white newsletterInputBox shadow w-100">
                <input
                  value={searchQuery}
                  onChange={handelSearchQuery}
                  className="ms-2 w-75   py-2 ps-3 newsletterInput1"
                  placeholder={pageData?.entername}
                />
                <button className="newsletterSubmit px-3 t-12 d-flex align-items-center justify-content-center">
                  {/* <span className="submitText text-white">Submit</span> */}
                  <i className="submitTextIcon text-white  bi bi-search"></i>
                </button>
              </div>
              <button className="newsletterSubmit shadow bg-white mx-2 px-3 t-12 d-flex align-items-center justify-content-center">
                <i className="filterIcon color-blue bi bi-filter"></i>
              </button>
            </div>
          </IsDisplayPartnersPageContent>
        </div>
        <IsDisplayPartnersPageContent>
          <div className="row m-0 py-3 mt-5  ">
            <div className="col-lg-6 mt-5   pe-5 ">
              <p className="color-blue my-3 font-weight-bold">
                {pageData?.courses}
              </p>
              <div className="partners-page-course">
                {filteredData.length > 0 ? (
                  filteredData?.map((item) => {
                    return (
                      <div onClick={() => handlePartnerCourse(item)}>
                        <div className="partnrs-card-details">
                          <img
                            className="image-partners-main"
                            src={partners1}
                          />
                          <div className="date-partners">{item.start_date}</div>
                          <img className="azure-card-logo" src={Azure} />
                        </div>
                        <div>
                          <div className="content-partners-page">
                            <p className="text-font-title">
                              {item.course_name.slice(0, 32) + "...."}
                            </p>
                            <p className="text-font-description">
                              {item.course_about.slice(0, 200) + "..."}
                            </p>
                            <div className="d-md-flex justify-content-between align-items-center">
                              <div className="levels-catehory">
                                <img src={level} />
                                <span className="medium">
                                  {item.course_trainer_level}
                                </span>
                              </div>
                              <div className="levels-catehory">
                                <img src={schedule} />
                                <span className="medium">
                                  {item.updatedAt.slice(15, 20) + "PM"}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <h1>No Data Found</h1>
                )}
              </div>
            </div>
            <div className="col-lg-6 mt-5   ">
              <p className="color-blue my-3 font-weight-bold mt-5 px-5">
                {pageData?.calender}
              </p>
              <CalendarComp />
            </div>
          </div>
        </IsDisplayPartnersPageContent>
        <IsDisplayPartnersPageContent isMessage={true}>
          <p
            style={{ fontSize: "20px", textAlign: "center", marginTop: "43px" }}
          >
            Login to see upcoming opportunities.
          </p>
        </IsDisplayPartnersPageContent>
      </div>
      <ToastContainer />
    </>
  );
};

export default Partners;
