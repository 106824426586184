import React from "react";
import "./HandsOnLabsCard.css";
import CourseImg from "../../assets/images/HandsOnLabs/HandsOnCard.png";
import challengeLogo from "../../assets/images/OurServices/challengeLogo.png";
import { useDispatch } from "react-redux";
import { handleActiveCourse } from "../../redux/slices/course/courseSlice";
import { Link, useNavigate } from "react-router-dom";
const CourseCard = ({ cardData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeCourse = (cardData) => {
    dispatch(handleActiveCourse(cardData));
  };
  return (
    <Link
      to={`/our-services/course/course-description?courseId=${cardData.id}&courseType=${cardData.course_type}`}
      onClick={() => activeCourse(cardData)}
      className="card border-15p shadow-sm h-100 td-none"
    >
      <div
        onClick={() => activeCourse(cardData)}
        className="card border-15p shadow-sm  h-100"
      >
         {cardData?.course_banner ? (
        <>
         <img  
          style={{width:"100%"}}
            alt="courseImage"
            className="card-img-top"
            src={cardData.course_banner}
          />
        </>
      ) : (
        <>
         <img   style={{width:"100%"}} alt="courseImage" className="card-img-top" src={CourseImg} />
        </>
      )}
        {/*<img   style={{width:"100%"}} className="card-img-top" src={CourseImg} alt="Cardimagecap" /> */}
        <div className="card-body position-relative">
          <span
            className="position-absolute course-description text-white py-1 px-2 bg-blue t-12 rounded d-flex align-items-center justify-content-center"
            style={{ top: "-35px" }}
          >
            <i className="bi me-2 bi-hourglass-split" />
            {cardData.course_duration}
          </span>
          <div className="row">
            <div className="col-9">
              <h5 className="card-title mb-1">
                {cardData.course_name.slice(0, 45)} ...
              </h5>
              <p className="course-description font-weight-light">
                {cardData.course_about.slice(0, 70)}...
              </p>
            </div>
            <div className="col-3">
              {/* <div className=" bg-light handsOnCardImg shadow-sm bg-white position-absolute d-flex border justify-content-center align-items-center"> */}
                {/*<img  
                  className=""
                  style={{ width: "25px" }}
                  src={challengeLogo}
                  alt="Cardimagecap"
                /> */}
              {/* </div> */}
            </div>
          </div>

          {/* <h6 className="card-title mb-3 font-weight-normal">Lorem ipsum dolor sit</h6>
                
                <div className="row">
                    <div className="col-6 d-flex align-items-center course-description ">
                        <i className="bi bi-reception-4 me-2 " style={{color: "#1B449c"}} />
                        <span>Intermediate</span>
                    </div>
                    <div className="col-6 d-flex align-items-center justify-content-center course-description ">
                        <i className="bi bi-clock-fill me-2 " style={{color: "#1B449c"}} />
                        <span>6 Hrs</span>
                    </div>
                </div> */}
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
