import React from 'react';
import "./HomepageTestimonialCard.css"
import Quote from "../../assets/images/Homepage/quote.png"
import User from "../../assets/images/Homepage/user.png"

const HomepageTestimonialCard = ({ testimonialData }) => {
    return (
        <div className=" testimonialCard px-5 py-4 border">
            <span className='d-flex mb-3 w-75'>
                <img className="textimonial_quote mb-5" src={Quote} alt="" />
            </span>
            <span className="testimonial-description-comp" >
                {testimonialData.testimonial_description}
            </span>
            <span className="d-flex mt-5">
            <img style={{borderRadius:"50%"}} className=" me-3 testimonial_user" src={testimonialData?.testimonial_image || User} alt="" />
                <span className=" testimonail-name" >
                    {testimonialData.testimonial_name}
                    <br />
                   <div className='job-profile-testimonisl'> {testimonialData.testimonial_job_profile
                    }</div>
                </span>
                
            </span>
        </div>
    );
};

export default HomepageTestimonialCard;