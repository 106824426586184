import {applicationEnv} from "../config/appConfig"


/* const getBaseUrl = () => {
  
    let url = env.BACKEND_URL;;
    if(applicationEnv === "Development")
    {
      url = "http://localhost:4003/api/v1/"
    }
    if(applicationEnv === "Staging")
    {
      url = "https://techno-api.moshimoshi.cloud/api/v1/"
    }
    if(applicationEnv === "Production"){
      url = " https://techno-api.eastus.cloudapp.azure.com/api/v1/"
    }
    return url;
  } */

/* export const authApiUrls = {
    googleAuthRoot: "https://accounts.google.com/o/oauth2/v2/auth",
    googleAuthRedirectUri: `${getBaseUrl()}auth/gmailSignUp`,
    googleAuthSignInRedirectUri: `${getBaseUrl()}auth/gmailSignIn`,
    linkedAuthRoot: "https://www.linkedin.com/oauth/v2/authorization",
    linkedinAuthRedirectSignUpUri: `${getBaseUrl()}auth/linkedin/callback`,
    linkedinAuthRedirectSignInUri: `${getBaseUrl()}auth/linkedin/signin/callback`,
    microsoftAuthRoot: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    microsoftAuthRedirectSignUpUri: `${getBaseUrl()}auth/microsoft/callback`,
    microsoftAuthRedirectSignInUri: `${getBaseUrl()}auth/microsoft/signin/callback`

} */

const getBaseUrl = process.env.BACKEND_URL;

export const authApiUrls = {
    googleAuthRoot: "https://accounts.google.com/o/oauth2/v2/auth",
    googleAuthRedirectUri: `${getBaseUrl}auth/gmailSignUp`,
    googleAuthSignInRedirectUri: `${getBaseUrl}auth/gmailSignIn`,
    linkedAuthRoot: "https://www.linkedin.com/oauth/v2/authorization",
    linkedinAuthRedirectSignUpUri: `${getBaseUrl}auth/linkedin/callback`,
    linkedinAuthRedirectSignInUri: `${getBaseUrl}auth/linkedin/signin/callback`,
    microsoftAuthRoot: "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
    microsoftAuthRedirectSignUpUri: `${getBaseUrl}auth/microsoft/callback`,
    microsoftAuthRedirectSignInUri: `${getBaseUrl}auth/microsoft/signin/callback`
}