import React from "react";
import "./ChallengeExperienceCard.css";
import CourseImg from "../../assets/images/HandsOnLabs/HandsOnCard.png";
// import challengeLogo from "../../assets/images/OurServices/challengeLogo.png"
// import HourGlass from "../../assets/images/HandsOnLabs/hourGlass.png"
import { useDispatch } from "react-redux";
import { handleActiveCourse } from "../../redux/slices/course/courseSlice";
import { Link, useNavigate } from "react-router-dom";

const CourseCard = ({ cardData, index }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeCourse = (cardData) => {
    dispatch(handleActiveCourse(cardData));
  };
  return (
    <Link
      to={`/our-services/course/course-description?courseId=${cardData.id}&courseType=${cardData.course_type}`}
      onClick={() => activeCourse(cardData)}
      className="card border-15p shadow-sm h-100 td-none"
    >
      <div
        onClick={() => activeCourse(cardData)}
        className="card border-15p shadow-sm h-100"
      >
        {cardData?.course_banner ? (
          <>
           <img  
              style={{ width: "100%" }}
              alt="courseImage"
              className="card-img-top"
              src={cardData.course_banner}
            />
          </>
        ) : (
          <>
           <img  
              style={{ width: "100%" }}
              alt="courseImage"
              className="card-img-top"
              src={CourseImg}
            />
          </>
        )}
        {/*<img   style={{width:"100%"}} className="card-img-top" src={CourseImg} alt="Cardimageap" /> */}
        <div className="card-body position-relative">
          <span
            className="position-absolute course-description text-white py-1 px-2 bg-secondary t-12 rounded d-flex align-items-center justify-content-center"
            style={{ top: "-50px" }}
          >
            <i className="bi me-2 bi-calendar" /> {cardData.schedule || "NA"}{" "}
          </span>
          <span
            className={`position-absolute course-description text-white py-1 px-2 t-12 rounded d-flex align-items-center justify-content-center ${
              index % 2 === 0 ? "bg-danger" : "bg-success"
            }`}
            style={{ top: "-50px", right: "20px" }}
          >
            <i className="bi me-2 bi-clock-fill" /> {cardData.course_duration}{" "}
            
          </span>
          <div className="">
            <h5 className="card-title mb-1">
              {cardData.course_name.slice(0, 45)} ...
            </h5>
            <p className="course-description font-weight-light">
              {cardData.course_about.slice(0, 70)}...
            </p>
          </div>
          {/* 
          <hr className="text-muted   " /> */}

          {/* <div className="d-flex justify-content-between">
            <span></span>
            <button className="service_btn color-blue t-12 bg-white border px-3 py-2 mt-2 border-primary">
              Register Now
              <i className=" service_btn_icon bi bi-arrow-up-right mx-2" />
            </button>
          </div> */}
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
