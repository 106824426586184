import React, { useState } from "react";
import BG1 from "../../assets/images/Partners/bg1.svg";
import AboutUsImage from "../../assets/images/aboutus/aboutus-bg1.svg"
import missionImg from "../../assets/images/aboutus/aboutus-mission.png";
import HeroSection from "../../components/HeroSection/HeroSection";
import Categories from "../../components/Categories/Categories";
import aboutusBG2 from "../../assets/images/aboutus/aboutusBG2.svg"
import doublequote from "../../assets/images/aboutus/quote-aboutus.svg"
import next from "../../assets/svgs/next.svg"
import back from "../../assets/svgs/back.svg"
import delloit from "../../assets/images/aboutus/delloit.svg"
import kpmg from "../../assets/images/aboutus/kpmg.svg"
import Ey from "../../assets/images/aboutus/Ey.svg"
import last from "../../assets/images/aboutus/last.svg"
import microsoft from "../../assets/images/aboutus/Microsoft.svg"
import { Button, Form } from "react-bootstrap";
import "./About.css";
import http from "../../services/axios/axiosInstance";
import { toast } from "react-toastify";
import MissionComp from "./MissionComp";
import VisionComp from "./VisionComp";
import missionimg from "../../assets/images/aboutus/mission.svg";
import visionimg from "../../assets/images/aboutus/vision.svg"
import ProgressBarCourse from "../../components/ProgressBar/ProgressBarCourse";
import CourseCarousel from "../../components/CourseCarousel/CourseCarousel";
import OurClientCarousels from "../../components/OurClientsCarousel/OurClientCarousels";
import PrevCarouselArrow from "../../assets/svgs/PrevCarouselArrow";
import NextCarouselArrow from "../../assets/svgs/NextCarouselArrow";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { changeLanguageText } from "../../services/helper";
import { parsePath } from "react-router-dom";
import { changeLanguage } from "../../redux/slices/language/languageSlice"
import { loadCaptchaEnginge, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';





function AboutUsPage() {

  const [selectData, setSelectedData] = useState()
  const [mission, setMission] = useState(true)
  const [upCourseCarouselPos, setUpCourseCarouselPos] = useState(0)
  const [upcomingCourseProgress, setUpcomingCourseProgress] = useState(8);
  const dispatch = useDispatch()


  const handleCarouselUpCoursePrev = () => {
    if (upCourseCarouselPos < 0) {
      setUpCourseCarouselPos(0);
      setUpcomingCourseProgress(0);
    } else {
      setUpCourseCarouselPos(upCourseCarouselPos - 10);
      setUpcomingCourseProgress(upCourseCarouselPos - 10);
    }
  };

  const handleCarouseUpcourselNext = (event) => {
    if (upCourseCarouselPos > 45) {
      setUpCourseCarouselPos(0);
      setUpcomingCourseProgress(0);
    } else {
      setUpCourseCarouselPos(upCourseCarouselPos + 10);
      setUpcomingCourseProgress(upCourseCarouselPos + 40);
    }
  };


  function ValidateEmail(input) {
    var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (input.match(validRegex)) {
      return true;

    } else {
      return false;

    }

  }

  const handleSubmit = async () => {
    try {
      const { full_name, email_address, messege } = selectData

      if ((!full_name || !email_address || !messege)) {
        toast.error('All fields required', { autoClose: 3000 })
        return
      }
      if (!ValidateEmail(email_address)) {
        toast.error('Invalid Email', { autoClose: 3000 })
        return
      }
      const payload = {
        full_name,
        email_address,
        messege,
      }


      let user_captcha_value = document.getElementById('user_captcha_input').value;

      if (validateCaptcha(user_captcha_value) == true) {
        toast.success('Captcha Matched ', { autoClose: 2000 })
        await http.post(`askandquestion`, payload)
        toast.success('Query Submitted ', { autoClose: 3000 })
        setSelectedData({ full_name: "", email_address: "", messege: "" })
      }
      else {
        toast.error('Captcha Does Not Match', { autoClose: 3000 })
      }

      // await http.post(`askandquestion`, payload)
      // toast.success('Query Submitted ', { autoClose: 3000 })
      // setSelectedData({ full_name: "", email_address: "", messege: "" })
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong!', { autoClose: 3000 })
    }





  }

  // const title = pageData.about
  const crumbs = [
    { name: "About Us", link: "/about-us" }
  ]
  const categoryData = [
    { id: 1, name: "Mission", value: "Mission", label: "Mission", img: missionimg },
    { id: 2, name: "Vision", value: "Vision", label: "Vision", img: visionimg }
  ];



  const clientsData = [
    { id: 1, img: microsoft },
    { id: 2, img: delloit },
    { id: 3, img: Ey },
    { id: 4, img: kpmg },
    { id: 5, img: last },
    { id: 6, img: microsoft },
    { id: 7, img: delloit },
    { id: 8, img: Ey },
    { id: 9, img: kpmg },
    { id: 10, img: last },


  ];

  const handleCategoryData = (cateData) => {
    if (cateData.name === "Mission") {
      setMission(1)
    } else {
      setMission(2)
    }
  }




  const activeLanguage = useSelector((state) => state.languageState.lang)
  const [pageData, setPageData] = useState({})

  const getTranslatedData = async () => {
    setPageData({
      aboutUs: await changeLanguageText("About Us", activeLanguage),

      brand: await changeLanguageText("ABOUT BRAND", activeLanguage),
      facliate: await changeLanguageText("Facilitating Value-Based Learning, Globally", activeLanguage),
      technoheading: await changeLanguageText("Technofocus is an organisation that is staunchly dedicated to enabling professionals in their pursuit of technical learning. Our carefully crafted experiential training programs have allowed learners to gain essential skills in the latest cloud features. Their learning experience is enhanced further by our field experts and certified, seasoned instructors.", activeLanguage),
      minute: await changeLanguageText("m+", activeLanguage),
      plus: await changeLanguageText("ABOUT BRAND", activeLanguage),
      trained1: await changeLanguageText("Individuals trained", activeLanguage),
      trained2: await changeLanguageText("globally", activeLanguage),
      trained3: await changeLanguageText("Technology courses", activeLanguage),
      trained4: await changeLanguageText("built", activeLanguage),
      trained5: await changeLanguageText("Types of learning", activeLanguage),
      trained6: await changeLanguageText("experiences curated", activeLanguage),
      trained7: await changeLanguageText("Training delivery", activeLanguage),
      trained8: await changeLanguageText("experts", activeLanguage),
      mission1: await changeLanguageText("Technofocus aims to revolutionise the learning experience in the  world of cloud computing to produce highly skilled professionals so  they can enhance their contributions to the goals of their  organisation.", activeLanguage),
      mission2: await changeLanguageText("Our vision is to make Technofocus a dynamic, vibrant, value-based learning organization comprising exceptionally skilled, motivated, and committed professionals who facilitate the seamless transformation of our learners into excellent performers in the industry.", activeLanguage),
      founder: await changeLanguageText("Ranjana Jain founded Technofocus in 2011 after she exited Microsoft in 2010. With a rich experience from her roles as a trainer, trainer-of-trainers, technology consultant, and evangelist, she wanted to realise her vision to deliver deep technical, quality learning experiences at a scale to students and industry professionals.", activeLanguage),
      founder1: await changeLanguageText("Ranjana Jain - Founder, Technofocus", activeLanguage),
      ourfounder: await changeLanguageText("Our Clients", activeLanguage),
      found: await changeLanguageText("Data Not Found", activeLanguage),
      havea: await changeLanguageText("Have a question?", activeLanguage),
      page: await changeLanguageText("Reach out to us.", activeLanguage),
      name: await changeLanguageText("Full Name ", activeLanguage),
      email: await changeLanguageText("Email Address", activeLanguage),
      message: await changeLanguageText("Message", activeLanguage),
      submit: await changeLanguageText("Submit", activeLanguage),



    })
  }
  const doSubmit = () => {

    let user_captcha_value = document.getElementById('user_captcha_input').value;

    if (validateCaptcha(user_captcha_value) == true) {
      alert('Captcha Matched');
    }
    else {
      alert('Captcha Does Not Match');
    }
  };



  useEffect(() => {
    dispatch(changeLanguage(localStorage.getItem("technoLang")))
    window.scrollTo({ top: 0, behavior: "smooth" });

  }, [])

  useEffect(() => {
    loadCaptchaEnginge(6);
  })



  useEffect(() => {
    getTranslatedData()
  }, [activeLanguage])


  return (
    <>
      <HeroSection title={pageData.aboutUs} crumbs={[{ name: pageData.aboutUs, link: "/about-us" }]} />

      <div className="main-container ">
        <div className="row partnersRow mb-5">
          {/* left panel */}
          <div className="col-lg-6 h-100">

            <div className="mt-5 position-relative w-70">
              {/* <p className="abouts-us-title">{pageData?.brand}</p> */}
              <h2>{pageData?.facliate}

              </h2>
              <p className="text-muted">{pageData?.technoheading}</p>
              <img className="position-absolute AboutUsBg1" src={BG1} alt=""/>
            </div>
          </div>

          {/* right panel */}
          <div className="col-lg-6 p-3 h-100">
            <div className="row px-2 p-lg-5 position-relative">
              <img src={AboutUsImage} className="objectFitCover p-0 shadow" id="partnersImage" alt="Technofocus partners page" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row text-center mt-5">
          <div className="col-md-3 ">
            <h2 className="about-us-count number-text-aboutus">
              10<span className="text-dark">{pageData?.minute}</span>
            </h2>
            <p className="about-us-count-text p-text inner-text-about-us">
              {pageData?.trained1}<br /> {pageData?.trained2}
            </p>
          </div>
          <div className="col-md-3">
            <h2 className="about-us-count number-text-aboutus">
              800<span className="text-dark">+</span>
            </h2>
            <p className="about-us-count-text p-text inner-text-about-us">
              {pageData?.trained3} <br />
              {pageData?.trained4}
            </p>
          </div>
          <div className="col-md-3">
            <h2 className="about-us-count number-text-aboutus ">
              {" "}
              80<span className="text-dark">+</span>
            </h2>
            <p className="about-us-count-text p-text inner-text-about-us">
              {pageData?.trained5}
              <br /> {pageData?.trained6}
            </p>
          </div>
          <div className="col-md-3">
            <h2 className="about-us-count number-text-aboutus">
              {" "}
              100<span className="text-dark">+</span>
            </h2>
            <p className="about-us-count-text p-text inner-text-about-us">
              {pageData?.trained7} <br />
              {pageData?.trained8}
            </p>
          </div>
        </div>
        <div className="row my-5 ">
          <div className="col-md-6">
            <img src={missionImg} className="img-fluid w-100" alt=""/>
          </div>
          <div className="col-md-6 misson-box-part  ">
            <Categories handleCategoryData={handleCategoryData} categoriesData={categoryData} />
            {/* {mission === 1 ? <><MissionComp /></> : null}
            {mission === 2 ? <><VisionComp /></> : null} */}
            {mission == 1 ? <p className="technofocus-abous-content">{pageData?.mission1}</p> : null}
            {mission == 2 ? <p className="technofocus-abous-content1">{pageData?.mission2}</p> : null}
          </div>
        </div>

        <div className=" row mb-5 pt-5 border-box-founder" >
          {/* ?left */}
          <div className="background-blue founder-box-left   col-lg-8 ">
            <div className="mt-5">
              {/* <img src={doublequote} /> */}
              <p className=" font-size-founder pt-5 ">{pageData?.founder}</p>
            </div>
            <p className="founder-technofocus">{pageData?.founder1}</p>
            {/* <img className="AboutUsBg2scan" src={BG1} /> */}
          </div>

          {/* right */}
          <div className="col-lg-4 position-relative-right">
            <div className="row founder-images-aboutus">
              <img id="partnersImage1" src={aboutusBG2} alt="partners_image"/>
            </div>
            <img className="AboutUsBg2scan2" src={BG1} alt=""/>
          </div>
        </div>


      </div>

      {/* ?our slients section? */}
      <div className="client-section  ">
        <div className="container d-flex justify-content-between align-items-center">
          <div className="our-clients">{pageData?.ourfounder}</div>

          <div className="carousel-nav-btns">
            <div
              onClick={handleCarouselUpCoursePrev}
              className="carousel-btn-prev"
            >
              <PrevCarouselArrow />
            </div>
            <div
              onClick={handleCarouseUpcourselNext}
              className="carousel-btn-next"
            >
              <NextCarouselArrow />
            </div>
          </div>
        </div>
        {/* <CourseCarousel corPosProp={upCourseCarouselPos}> */}
        <div className="container">
          <CourseCarousel corPosProp={upCourseCarouselPos}>
            {clientsData.length > 0 ? (
              clientsData?.map((el, idx) => {
                return (
                  <OurClientCarousels
                    index={idx + 1}
                    cardData={el}


                  />
                );
              })
            ) : (
              <div>{pageData?.found}</div>
            )}
          </CourseCarousel>
        </div>
        <ProgressBarCourse now={upcomingCourseProgress} />

      </div >
      {/* have a question section */}
      < div className=" bgourquetion" >
        <div className="boxes-questions row" >
          <div className="questions col-md-7">
            <p className="have-a-h1">{pageData?.havea}</p>
            <p className="reach-us">{pageData?.page}</p>
          </div>
          <div className="questions-answer-updates col-md-5">
            <input
              placeholder={pageData?.name}
              className=" input-one mb-3 "
              value={selectData?.full_name}
              onChange={(e) => setSelectedData({ ...selectData, full_name: e.target.value })}

            />

            <input
              placeholder={pageData?.email}
              className=" input-one mb-3  "
              value={selectData?.email_address}
              onChange={(e) => setSelectedData({ ...selectData, email_address: e.target.value })}
            />

            <Form.Control
              className="hello-chats"
              as="textarea"
              rows={5}
              placeholder={pageData?.message}
              value={selectData?.messege}
              onChange={(e) => setSelectedData({ ...selectData, messege: e.target.value })}
            />
            <div className='py-3'>
              <LoadCanvasTemplateNoReload />
            </div>

            <input
              id='user_captcha_input'
              placeholder='Fill Captcha'
              className="input-one"

            />

            <button className="mt-5" onClick={handleSubmit}>{pageData?.submit}</button>
          </div>
        </div>

      </div >
    </>
  );
}

export default AboutUsPage;
